
import Vue from 'vue'
import MallCardX from '../../common/widgets/MallCardX.vue'
import SubscribeMallDialog from '../../views/dibs/SubscribeMallDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import Slider from '../../common/slider/Slider.vue'

export default Vue.extend({
  name: 'SubscribeMall',
  components: {
    MallCardX,
    SubscribeMallDialog,
    Slider,
  },
  data() {
    return {
      tab: 0,
      active: 0,
      search: {
        flag: 0,
        page: 0,
      },
      pinDialog: false,
      selectedShopInfo: {},
      storeHomeDialog: false,
      storeHomeShopId: '',
      selectIndex: 0,
    }
  },
  computed: {
    ...mapGetters('DibsStore', [
      'getDibsSubscribeMall',
      'getDibsSubscribeMallItem',
    ]),
  },
  methods: {
    ...mapActions('HomeStore', ['fetchMainTab']),
    ...mapActions('StoreHomeStore', ['showStoreHomeDialog']),
    ...mapActions('DibsStore', ['fetchSwipe']),
    goPage(item: ShopInfo, index: number) {
      if (!item.shopId) return

      this.$tracking(`like_mymall_mall${this.$numbering(index + 1)}`)
      let shopId: string = item.shopId
      this.$router.push({ name: 'StoreHome', query: { shopId } })
    },
    openDialog(item: object, index: number) {
      this.$tracking(`like_mymall_mall${this.$numbering(index + 1)}_more`)
      this.selectedShopInfo = item
      this.selectIndex = index
      this.pinDialog = !this.pinDialog
    },
    closeDialog() {
      const replaceNum = this.$numbering(this.selectIndex + 1)
      this.$tracking(`like_mymall_mall${replaceNum}_more_close`)
      this.pinDialog = false
    },
    goTopShop() {
      this.$router.push({
        name: 'CategoryTopShopList',
        query: {
          categoryName: 'Top Shop',
        },
      })
    },
    goStoreTab() {
      this.fetchMainTab({ tab: 1 })
      this.$router.push('/')
    },
    goHome(item: SubscribeShopItem, index: number) {
      if (!item.shopId) return

      let shopId: string = item.shopId
      this.$router.push({ name: 'StoreHome', query: { shopId } })
    },
    dibsSwipeHandler(data: string) {
      if (data !== 'off') {
        this.fetchSwipe({ yn: true })
      } else {
        setTimeout(() => {
          this.fetchSwipe({ yn: false })
        })
      }
    },
    goStoreHome() {
      const replaceNum = this.$numbering(this.selectIndex + 1)
      this.$tracking(`like_mymall_mall${replaceNum}_more_storehome`)
    },
    onPin() {
      const replaceNum = this.$numbering(this.selectIndex + 1)
      this.$tracking(`like_mymall_mall${replaceNum}_more_pin`)
    },
    openShare() {
      const replaceNum = this.$numbering(this.selectIndex + 1)
      this.$tracking(`like_mymall_mall${replaceNum}_more_share`)
    },
    closeShare(type: string) {
      if (type) {
        const replaceNum = this.$numbering(this.selectIndex + 1)
        this.$tracking(`like_mymall_mall${replaceNum}_more_share_${type}`)
      }
    },
  },
})
