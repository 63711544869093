
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import DibsProduct from '@/components/views/dibs/DibsProduct.vue'
import SubscribeMall from '@/components/views/dibs/SubscribeMall.vue'
import { GetDibsProductCategoryInfo } from '@/api/displayAPI/DibsAPI'
import Slider from '@/components/common/slider/Slider.vue'
import { onRequestAdonBanner } from '@/helper/banner'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DibsView',
  components: {
    DibsProduct,
    SubscribeMall,
    Slider,
  },
  props: {
    top: {
      type: String,
      default: '2.5em',
    },
  },
  data() {
    return {
      filterTab: 0, // [0: acitve가 dibs 일 때, 1: acitve가 subs 일 떄]
      filterItem: [
        { tab: '찜 상품', to: 'DibsProduct' },
        { tab: '구독 몰', to: 'SubscribeMall' },
      ],
      data: {
        categoryList: [],
      },
      activeCate: {
        categoryCode: 0,
        categoryName: '',
        productCount: 0,
      },
      scroll: 0,
    }
  },
  async created() {
    this.$getAppHtml.addEventListener('scroll', this.setScroll)
    window.addEventListener('beforeunload', this.setHistoryScroll)

    const { acitve } = this.$router.currentRoute.query
    if (acitve) {
      if (acitve === 'dibs') {
        this.filterTab = 0
      }
      if (acitve === 'subs') {
        this.filterTab = 1
      }
      await this.fetchDibsSearchInfo({
        ...this.dibsSearchInfo,
        activeTab: acitve === 'dibs' ? 0 : 1,
      })
    }
    if (this.dibsSearchInfo.activeTab) {
      this.filterTab = this.dibsSearchInfo.activeTab
    }
    this.fetch()
    this.asyncGetDibsSubscribe()
  },
  beforeDestroy() {
    this.setHistoryScroll()
    this.$getAppHtml.removeEventListener('scroll', this.setScroll)
    window.removeEventListener('beforeunload', this.setHistoryScroll)
  },
  computed: {
    ...mapState('DibsStore', [
      'dibsSwipeBlock',
      'dibsSearchInfo',
      'dibsScroll',
      'dibsRefresh',
    ]),
    ...mapState('BannerStore', ['onBanner']),
    fixDibsBar() {
      return {
        position: 'sticky',
        'z-index': '2',
        'background-color': 'white',
      }
    },
    headerAnimClass() {
      if (this.onBanner) {
        return `common-header-visible`
      } else {
        return 'common-header-animate'
      }
    },
  },
  watch: {
    async filterTab(activeTab, preTab) {
      this.fetchDibsScroll({ scroll: this.scroll, pre: preTab })
      if (activeTab === 0) {
        await this.fetch()
      }
      this.redirectScroll()
    },
    dibsRefresh() {
      if (this.dibsRefresh && this.filterTab === 0) {
        this.fetch()
        this.fetchDibsSearchInfo({
          ...this.dibsSearchInfo,
          category: {
            categoryCode: 0,
            categoryName: '',
            productCount: 0,
          },
        })
        this.fetchRefresh(false)
        this.$vuetify.goTo(0)
        this.fetchLoading({ yn: true })
      }
      if (this.dibsRefresh && this.filterTab === 1) {
        this.DibsData()
        this.fetchRefresh(false)
        this.$vuetify.goTo(0)
        this.fetchLoading({ yn: true })
      }
    },
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('MyPageStore', ['asyncGetMyPage']),
    ...mapActions('DibsStore', [
      'asyncGetDibsSubscribe',
      'fetchSwipe',
      'fetchDibsSearchInfo',
      'fetchDibsScroll',
      'fetchRefresh',
    ]),
    handleChangeFilter(key: number) {
      this.$tracking(`like_${key === 0 ? 'product' : 'mymall'}`)
      this.filterTab = key
      this.fetchDibsSearchInfo({
        ...this.dibsSearchInfo,
        activeTab: key,
      })
    },
    async fetch(reload?: boolean) {
      try {
        const { data } = await GetDibsProductCategoryInfo()
        this.data = data
        if (!reload && data.categoryList.length > 0) {
          this.activeCate = data.categoryList[0]
          this.fetchDibsSearchInfo({
            ...this.dibsSearchInfo,
            category: { ...this.activeCate },
          })
        }
      } catch (e) {
        console.log('찜 카테고리 리스트 e', e)
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
    async DibsData() {
      try {
        this.fetchLoading({ yn: true })
        await this.asyncGetDibsSubscribe()
        await this.asyncGetMyPage()
      } catch (e) {
        console.log('찜 구독몰정보 e', e)
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
    onCateActive(active: DibsTabCategoryItem) {
      this.$tracking(`like_product_cate_${active.categoryCode}`)
      if (active.productCount != 0) {
        this.activeCate = active
        this.fetchDibsSearchInfo({
          ...this.dibsSearchInfo,
          category: { ...active },
          saleFlag: false,
          availableFlag: false,
        })
      }
    },
    dibsSwipeHandler(data: string) {
      if (data !== 'off') {
        this.fetchSwipe({ yn: true })
      } else {
        setTimeout(() => {
          this.fetchSwipe({ yn: false })
        })
      }
    },
    setScroll() {
      this.scroll = this.$getAppHtml.scrollTop
    },
    setHistoryScroll() {
      this.fetchDibsScroll({ scroll: this.scroll })
    },
    redirectScroll() {
      this.$getAppHtml.scrollTo({
        top: this.dibsScroll[this.filterTab],
      })
    },
    handleRefresh() {
      this.fetchDibsSearchInfo({
        ...this.dibsSearchInfo,
        saleFlag: false,
        availableFlag: false,
      })

      this.fetchRefresh(true)

      if (this.$route.name) onRequestAdonBanner(this.$route.name)
    },
  },
})
interface Data {
  filterTab: number
  data: DibsTabCategory
  activeCate: DibsTabCategoryItem
  scroll: number
}
interface Methods {
  handleChangeFilter(key: number): void
  fetch(reload?: boolean): void
  DibsData(): void
  onCateActive(data: DibsTabCategoryItem): void
  asyncGetDibsSubscribe: (payload?: { delayMs: number }) => Promise<void>
  asyncGetMyPage: (payload?: { delayMs: number }) => Promise<void>
  dibsSwipeHandler(data: string): void
  fetchSwipe: (payload: { yn: boolean }) => void
  fetchDibsSearchInfo: (payload: DibsSearchInfo) => void
  setScroll: () => void
  setHistoryScroll: () => void
  fetchDibsScroll: (payload: { scroll: number; pre?: number }) => void
  redirectScroll(): void
  fetchRefresh: (payload: boolean) => void
  fetchLoading: (payload: { yn: boolean }) => void
  handleRefresh: () => void
}
interface Computed {
  headerAnimClass: string
  onBanner: boolean
  dibsSwipeBlock: boolean
  fixDibsBar: object
  dibsSearchInfo: DibsSearchInfo
  dibsScroll: number[]
  dibsRefresh: boolean
}
interface Props {
  top: string
}
