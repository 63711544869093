
import Vue from 'vue'
import { mapActions } from 'vuex'
import DibsView from '@/components/views/dibs/Dibs.vue'
import { DibsState } from '@/store/DibsStore'
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DibsMain',
  components: { DibsView },
  async created() {
    try {
      this.fetchLoading({ yn: true })
      this.fetchData()
    } catch (error) {
      console.log(error)
    } finally {
      this.fetchLoading({ yn: false })
    }

    if (localStorage.getItem('dibsStateHistory')) {
      const { dibsProductList, dibsScroll }: DibsState = JSON.parse(
        Object(localStorage.getItem('dibsStateHistory'))
      )

      this.fetchDibsProductList(dibsProductList)
      this.fetchScrollHistory(dibsScroll)

      localStorage.removeItem('dibsStateHistory')
    } else {
      this.fetchOnBanner({ onBanner: true })
    }
  },
  watch: {
    isLogined(yn) {
      if (!yn) {
        localStorage.setItem('redirectPath', '/dibs')
        this.$router.push('/mypage')
      }
    },
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('MyPageStore', ['asyncGetMyPage']),
    ...mapActions('DibsStore', [
      'asyncGetDibsSubscribe',
      'fetchDibsProductList',
      'fetchScrollHistory',
    ]),
    ...mapActions('BannerStore', ['fetchOnBanner']),
    async fetchData() {
      await this.asyncGetMyPage()
      await this.asyncGetDibsSubscribe()
    },
  },
  computed: {},
})

interface Data {}
interface Methods {
  fetchData(): void
  fetchLoading(payload: { yn: boolean }): Promise<void>
  asyncGetMyPage(): Promise<void>
  asyncGetDibsSubscribe(): Promise<void>
  fetchDibsProductList(payload: ProductItem[]): Promise<void>
  fetchScrollHistory(payload: Number[]): Promise<void>
  fetchOnBanner(payload: { onBanner: boolean }): Promise<void>
}
interface Computed {}
interface Props {}
