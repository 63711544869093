
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'
import DibsBottomSheet from '@/components/views/dibs/component/DibsBottomSheet.vue'
import Confirm from '@/components/common/dialogs/Confirm.vue'
import { GetDibsProductInfo } from '@/api/displayAPI/DibsAPI'
import { DeleteLikeProduct } from '@/api/displayAPI/UserAPI'

const DEFAULT_PAGE = 0
const DEFAULT_PAGE_LIMIT = 1000

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DibsProduct',
  components: {
    ProductCardY,
    DibsBottomSheet,
    Confirm,
  },
  filters: {
    editCheckYn(productNo: string, list: ProductItem[]) {
      return list.some((item) => item.productNo === productNo)
    },
  },
  props: {
    activeCate: {
      type: Object,
      default: () => ({
        categoryCode: 0,
        categoryName: '',
        productCount: 0,
      }),
    },
    filterTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      clickList: [],
      page: 1,
      limit: 1000,
      onEdit: false,
      editList: [], // 일방 상품 찜 취소 / 삭제
      deleteList: [], // 이용불가 상품 찜 취소
      allCheck: false,
      onConfirm: false,
      apiCall: false,
    }
  },
  computed: {
    ...mapState('DibsStore', [
      'dibsSearchInfo',
      'dibsProductList',
      'dibsCount',
    ]),
    allCheckText() {
      this.$tracking('like_product_edit_seleteall')
      return this.allCheck ? '선택해제' : '전체선택'
    },
    currentReload() {
      return this.dibsProductList.length > 0
    },
  },
  watch: {
    activeCate() {
      this.onEdit = false
      this.search()
    },
    async editList() {
      this.allCheck = this.dibsProductList.length === this.editList.length
    },
    dibsProductList: {
      immediate: true,
      handler() {
        if (!this.apiCall && this.dibsProductList.length) {
          this.apiCall = true
          setTimeout(() => {
            this.$emit('redirectScroll')
          })
        }
      },
    },
  },
  async created() {
    if (!this.dibsProductList.length) {
      this.search()
    }
  },
  methods: {
    ...mapActions('DibsStore', [
      'fetchDibsSearchInfo',
      'fetchDibsProductList',
      'fetchCount',
    ]),
    async search() {
      try {
        const { categoryName, categoryCode } = this.activeCate
        const { saleFlag, availableFlag } = this.dibsSearchInfo
        const params = {
          filterValue: categoryName === '전체' ? 0 : categoryCode,
          isSale: saleFlag ? 1 : 0,
          isAvailable: availableFlag ? 1 : 0,
          page: DEFAULT_PAGE,
          limit: DEFAULT_PAGE_LIMIT,
        }
        const { data } = await GetDibsProductInfo({ ...params })
        this.fetchDibsProductList(data.searchProductList ?? [])
        this.fetchCount(data.count)

        this.$criteo({
          event: 'viewBasket',
          item: data.searchProductList.map((item) => {
            const { productNo, discountPrice } = item
            return { id: productNo, price: discountPrice, quantity: '1' }
          }),
        })
      } catch (e) {
        console.log('찜 카테고리 리스트 e', e)
      } finally {
        this.apiCall = true
      }
    },
    changeStatus(searchType: string) {
      const { saleFlag, availableFlag } = this.dibsSearchInfo
      this.page = 1

      if (searchType === 'sale') {
        this.$tracking(`like_product_saleonly`)
        this.fetchDibsSearchInfo({
          ...this.dibsSearchInfo,
          saleFlag: !saleFlag,
        })
      }
      if (searchType === 'available') {
        this.$tracking(`like_product_available`)
        this.fetchDibsSearchInfo({
          ...this.dibsSearchInfo,
          availableFlag: !availableFlag,
        })
      }
      this.search()
    },
    goPage() {
      this.$router.push({
        name: 'CategoryProductList',
        query: {
          categoryCode: '7',
          categoryName: '전체',
        },
      })
    },
    handleEdit() {
      // TODO
      this.editList = []
      this.onEdit = !this.onEdit
      if (this.onEdit) {
        this.$tracking('like_product_edit')
        this.fetchDibsSearchInfo({
          ...this.dibsSearchInfo,
          saleFlag: false,
          availableFlag: false,
        })
        this.search()
      } else {
        this.$tracking('like_product_edit_complete')
      }
    },
    async handleDelete() {
      this.onConfirm = false
      await this.dibsDelete()
      this.reload()
    },
    async handleProduct(product: ProductItem) {
      if (this.dibsProductList.length - 1 === 0) this.onEdit = false
      this.deleteList = [product]
      await this.dibsDelete(true)
      this.reload()
    },
    handleEditList(product: ProductItem, index: number) {
      this.$tracking(`like_product_edit_${this.$numbering(index + 1)}`)
      let useYn = this.editList.some(
        (item) => item.productNo === product.productNo
      )
      if (useYn) {
        this.editList = this.editList.filter(
          (item) => item.productNo !== product.productNo
        )
      } else {
        this.editList.push(product)
      }
    },
    async dibsDelete(isAvailable?: boolean) {
      try {
        await DeleteLikeProduct(!isAvailable ? this.editList : this.deleteList)
        if (!isAvailable) {
          this.onEdit = false
          this.editList = []
        }
        await this.search()
      } catch (e) {
        console.log('dibs like delete e')
      }
    },
    async allActive() {
      this.editList = []
      if (!this.allCheck) {
        this.editList = this.dibsProductList
      }
    },
    reload() {
      // list가 0보다 많으면 현재 페이지 currentReload / 0개일 경우 page init처럼 전체로 이동
      this.$emit('reload', this.currentReload)
    },
    openShare(index: number) {
      this.$tracking(`like_product_${this.$numbering(index + 1)}_share`)
    },
    closeShare(type: string, index: number) {
      this.$tracking(`like_product_${this.$numbering(index + 1)}_share_${type}`)
    },
  },
})
interface Data {
  clickList: Array<DibsLikeProductList>
  page: number
  limit: number
  onEdit: boolean
  editList: ProductItem[]
  allCheck: boolean
  deleteList: ProductItem[]
  onConfirm: boolean
  apiCall: boolean
}

interface Methods {
  fetchDibsSearchInfo: (payload: DibsSearchInfo) => void
  goPage(): void
  search(type?: string): void
  changeStatus(searchType: string): void
  handleEdit(): void
  handleEditList(products: ProductItem, index: number): void
  dibsDelete(isAvailable?: boolean): void
  allActive(): void
  handleDelete(): void
  handleProduct(products: ProductItem): void
  reload(): void
  fetchDibsProductList(payload: ProductItem[]): void
  openShare(index: number): void
  closeShare(type: string, index: number): void
  fetchCount(payload: number): void
}
interface Computed {
  allCheckText: string
  dibsSearchInfo: DibsSearchInfo
  currentReload: boolean
  dibsProductList: ProductItem[]
  dibsCount: number
}
interface Props {
  activeCate: {
    categoryCode: number
    categoryName: string
    productCount: number
  }
  filterTab: number
}
