
import Vue from 'vue'
export default Vue.extend<Data, Computed, Methods, Props>({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    popup: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEmit(event) {
      if (this.popup) return
      this.$emit(`${event}`)
    },
  },
})
interface Data {}
interface Computed {
  handleEmit(event: string): void
}
interface Methods {}
interface Props {
  show: boolean
  count: number
  popup: boolean
}
