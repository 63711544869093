
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import Mall from '@/components/common/cards/Mall.vue'
import Alert from '@/components/common/dialogs/Alert.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Mall, Alert },
  props: {
    /**
     * Mall Card X type <Props>
     * image : 상품 이미지
     * title : 제목
     * price : 가격
     * rate : 할인율
     * likeCount : 찜 카운트
     * viewCount : 뷰 카운트
     * color : 할인율에 색상 ( default : #003399 : 보라 - Figma 참조 )
     * subscribeIconSize : 구독 아이콘 사이즈 width, height 같이 지정 (단위x)
     */
    image: {
      type: String,
      default: '',
    },
    store: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: null,
    },
    rate: {
      type: Number,
      default: null,
    },
    ratePrice: {
      type: Number,
      default: null,
    },
    productCount: {
      type: Number,
      default: null,
    },
    subscribeCount: {
      type: Number,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: 1 / 1,
    },
    isSubscribed: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: String,
      default: '35%',
    },
    mallSize: {
      type: String,
      default: '60',
    },
    sex: {
      type: String,
      default: '',
    },
    ageKr: {
      type: String,
      default: '',
    },
    shopId: {
      type: String,
      default: '',
    },
    dialogType: {
      type: Boolean,
      default: null,
    },
    subsProduct: {
      type: Object,
      default: () => ({
        isAvailable: true,
        shopId: '',
      }),
    },
    subscribeIconSize: {
      type: String,
      default: '24',
    },
    gtagName: {
      type: String,
      default: '',
    },
    borderActive: {
      type: Boolean,
      default: false,
    },
    defaultBorderColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: this.isSubscribed,
      alert: {
        show: false,
        message: '',
      },
    }
  },
  computed: {
    ...mapState('PullToRefreshStore', ['refreshNoti']),
  },
  watch: {
    refreshNoti() {
      this.active = this.isSubscribed
    },
    isSubscribed() {
      this.active = this.isSubscribed
    },
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    async subscribe() {
      try {
        if (this.gtagName) {
          this.$tracking(`${this.gtagName}_subscribe`)
        }

        await PostSubscribe(this.shopId, this.store)
        this.$emit('isSubscribeChange', true, this.shopId)
        this.active = true
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe() {
      try {
        if (!this.shopId) return
        if (this.gtagName !== '') {
          this.$tracking(`${this.gtagName}_unsubscribe`)
        }
        await DeleteSubscribe(this.shopId, this.store)
        this.$emit('isSubscribeChange', false, this.shopId)
        this.active = false
      } catch (e) {
        console.log(e)
      }
    },
    async handleSubscribed() {
      if (!this.isLogined) return this.fetchLoginDialog({ yn: true })
      this.active ? await this.unSubscribe() : await this.subscribe()
    },
    async unAvailableMall() {
      try {
        if (!this.shopId) return
        await DeleteSubscribe(this.shopId, this.store)
      } catch (e) {
        console.log(e)
      } finally {
        this.$router.push({ name: 'Dibs', query: { acitve: 'subs' } })
        this.$router.go(0)
      }
    },
  },
})

interface Data {
  active: boolean
  alert: {
    show: boolean
    message: string
  }
}
interface Methods {
  fetchLoginDialog({ yn }: { yn: boolean }): void
  handleSubscribed(): void
  subscribe(): void
  unSubscribe(): void
  unAvailableMall(): void
}
interface Computed {
  refreshNoti: boolean
}
interface Props {
  image: string
  store: string
  title: string
  price: number
  rate: number
  ratePrice: number
  productCount: number
  subscribeCount: number
  color: string
  aspectRatio: number
  isSubscribed: boolean
  radius: string
  mallSize: string
  sex: string
  ageKr: string
  shopId: string
  dialogType: boolean
  subsProduct: object
  subscribeIconSize: string
  gtagName: string
  defaultBorderColor: string
  borderActive: boolean
}
