var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"filp-banner pb-64"},[_c('v-tabs',{staticClass:"main-header-top",class:_vm.headerAnimClass,style:(_vm.fixDibsBar),attrs:{"background-color":"white","color":"gray400"},model:{value:(_vm.filterTab),callback:function ($$v) {_vm.filterTab=$$v},expression:"filterTab"}},_vm._l((_vm.filterItem),function(item,index){return _c('div',{key:item.to,staticClass:"d-flex justify-center align-center text-center font-g15-16 fill-width fill-height border-bottom-gray border-top-gray ma-auto rixgo-bold",class:`${_vm.filterTab === index && 'white--text gray900'}`,on:{"click":function($event){return _vm.handleChangeFilter(index)}}},[(index === 0)?_c('div',[_vm._v("찜 상품")]):_vm._e(),(index === 1)?_c('div',[_vm._v("구독 몰")]):_vm._e()])}),0),_c('v-tabs-items',{staticClass:"fill-width fill-height",attrs:{"touchless":_vm.dibsSwipeBlock},model:{value:(_vm.filterTab),callback:function ($$v) {_vm.filterTab=$$v},expression:"filterTab"}},[(_vm.filterTab === 0)?_c('div',[_c('PullToRefresh',{on:{"handleRefresh":_vm.handleRefresh}},[_c('Slider',{staticClass:"border-bottom-gray",attrs:{"touchless":"DibsStore","sliderInName":"dibsTabItem","list":_vm.data.categoryList,"itemClass":"relative py-9 pr-10 pl-5 text-center d-flex","itemActiveClass":[
            'campton-bold gray900--text',
            'campton-bold gray300--text',
          ],"swipeClass":"gray100","swipeSliderClass":"font-g12-13 px-3","activeKey":"categoryName","differentItemClass":['', '', ''],"active":{
            yn: true,
            item: _vm.activeCate,
          },"centerActive":true},on:{"handleActive":_vm.onCateActive}}),_c('DibsProduct',{staticStyle:{"min-height":"64vh"},attrs:{"activeCate":_vm.activeCate,"filterTab":_vm.filterTab},on:{"reload":_vm.fetch,"redirectScroll":_vm.redirectScroll}})],1)],1):_vm._e(),(_vm.filterTab === 1)?_c('div',[_c('PullToRefresh',{on:{"handleRefresh":_vm.handleRefresh}},[_c('SubscribeMall',{on:{"redirectScroll":_vm.redirectScroll}})],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }