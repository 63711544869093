
import Vue from 'vue'
import MallCardX from '../../common/widgets/MallCardX.vue'
import Sharing from '../../common/dialogs/Sharing.vue'
import Button from '@/components/common/buttons/Button.vue'

// import StoreHomeDialog from '@/components/views/storeHome/StoreHomeDialog.vue'

import { PutSubscribePin } from '@/api/displayAPI/UserAPI'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { MallCardX, Sharing, Button },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    selectedShopInfo: {
      type: Object,
      default: () => ({
        shopId: '',
        shopName: '',
        icon: '',
        link: '',
        nameKr: '',
        isNew: false,
        isPin: false,
        sex: '',
        age: 0,
        ageKr: '',
        subscribeCount: 0,
        isSubscribed: false,
        isAvailable: false,
      }),
    },
  },
  data: () => ({
    show: false,
    sheet: true,
    storeHomeDialog: false,
    storeHomeShopId: '',
  }),
  computed: {
    ...mapGetters('DibsStore', ['getDibsSubscribeMallItem']),
    shareUrl() {
      return `${process.env.VUE_APP_CELLOOK_URL}/?shopId=${this.selectedShopInfo.shopId}`
    },
  },
  methods: {
    ...mapActions('DibsStore', ['asyncGetDibsSubscribe']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    async fixPin() {
      let promise
      if (this.selectedShopInfo.isPin) {
        promise = PutSubscribePin({
          shopId: this.selectedShopInfo.shopId,
          isPin: false,
        })
      } else {
        this.$emit('onPin')
        promise = PutSubscribePin({
          shopId: this.selectedShopInfo.shopId,
          isPin: true,
        })
      }
      promise.then(() => {
        this.$emit('closeDialog')
        this.fetchLoading({ yn: false })
        this.asyncGetDibsSubscribe()
      })
    },
    openShare() {
      this.$emit('openShare')
      this.show = true
    },
    goPage(shopId: string) {
      if (!shopId) return
      this.$emit('goStoreHome')
      this.$router.push({ name: 'StoreHome', query: { shopId } })
    },
    closeSubscribeMallDialog() {
      this.$emit('closeDialog')
    },
    closeShare(type: string) {
      this.$emit('closeShare', type)
      this.show = false
    },
  },
})
interface Data {
  show: boolean
  sheet: boolean
  storeHomeDialog: boolean
  storeHomeShopId: string
}
interface Methods {
  fixPin(): void
  fetchLoading(yn: { yn: boolean }): void
  openShare(data: string): void
  asyncGetDibsSubscribe(): void
  goPage(shopId: string): void
  closeSubscribeMallDialog(): void
  closeShare(type: string): void
}
interface Computed {}
interface Props {
  dialog: boolean
  selectedShopInfo: any
}
